<template>
  <main class="w-full h-screen flex items-center justify-center p-6">
    <div class="bg-gray-900 px-12 py-6 rounded-3xl relative text-center">
      <h1 class="text-xl sm:text-3xl font-semibold mb-2">Universities.sg</h1>
      <h2 class="sm:text-lg font-semibold mb-8">
        by
        <div class="inline-block relative">
          <a
            class="underline font-bold bg-clip-text text-transparent bg-gradient-to-r from-red-300 to-purple-400"
            href="https://sgunistats.com"
            >SGUniStats</a
          >
          <div
            class="absolute w-full h-0.25 bg-gradient-to-r from-red-300 to-purple-400"
          ></div>
        </div>
      </h2>
      <p class="mb-4 text-sm">
        This website is currently&nbsp;a&nbsp;work in
        progress.&nbsp;Stay&nbsp;tuned!
      </p>
      <!-- <p class="mb-4 text-sm">
        Target: <strong class="font-semibold">2023 Q2</strong>
      </p> -->

      <!-- Logo and Social Links -->
      <div class="flex justify-center mb-4">
        <div class="flex gap-x-2 items-center mb-2">
          <a
            href="https://www.nathanielcalimag.com"
            target="_blank"
            rel="noopener noreferrer"
            class="duration-300 sm:hover:text-sky-600"
          >
            <svg
              class="w-6 h-6"
              viewBox="0 0 42.333332 42.333332"
              version="1.1"
              id="svg5"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="portfolioTitle"
            >
              <title id="portfolioTitle">Portfolio link</title>
              <g id="layer1">
                <path
                  id="text3262"
                  style="
                    fontstyle: 'normal';
                    fontvariant: 'normal';
                    fontweight: '900';
                    fontstretch: 'normal';
                    fontsize: '50.8px';
                    lineheight: '1.25';
                    fontfamily: 'Apple SD Gothic Neo';
                    fontvariantligatures: 'normal';
                    fontvariantcaps: 'normal';
                    fontvariantnumeric: 'normal';
                    fontvarianteastasian: 'normal';
                  "
                  fill="currentColor"
                  d="M 0,0 V 34.137451 H 8.2132055 V
                  12.852445 H 8.4184181 L 20.737965,34.137451 h 8.715012 A
                  7.8975412,10.861648 0 0 1 24.912189,24.309627
                  7.8975412,10.861648 0 0 1 29.926062,14.202751 V 0 h -8.212684
                  v 19.100643 h -0.15404 L 10.420416,0 Z"
                />
                <path
                  id="text3926"
                  style="
                    fontstyle: 'normal';
                    fontvariant: 'normal';
                    fontweight: '900';
                    fontstretch: 'normal';
                    fontsize: '50.8px';
                    lineheight: '1.25';
                    fontfamily: 'Apple SD Gothic Neo';
                    fontvariantligatures: 'normal';
                    fontvariantcaps: 'normal';
                    fontvariantnumeric: 'normal';
                    fontvarianteastasian: 'normal';
                  "
                  fill="currentColor"
                  d="m 33.401623,7.0781208 c -9.496302,0
                  -17.04205,4.6733692 -17.04205,17.1193682 v 1.168404 c
                  0,11.582399 6.211199,16.96744 16.682798,16.96744 4.166341,0
                  6.999784,-0.682586 9.290962,-1.655712 v -6.820258 c
                  -1.688956,0.65377 -3.856699,1.262457 -7.083751,1.262457
                  -6.365089,0 -8.57246,-3.555902 -8.57246,-10.058301 v -1.523938
                  c 0,-6.5532 3.131539,-9.245947 8.675326,-9.245947 2.952448,0
                  4.94549,0.45034 6.980885,1.20096 V 8.5276448 c
                  -1.947041,-0.9492543 -4.656599,-1.449524 -8.93171,-1.449524 z"
                />
                <path
                  id="text3926-6"
                  style="
                    fontstyle: 'normal';
                    fontvariant: 'normal';
                    fontweight: '900';
                    fontstretch: 'normal';
                    fontsize: '25.4px';
                    lineheight: '1.25';
                    fontfamily: 'Apple SD Gothic Neo';
                    fontvariantligatures: 'normal';
                    fontvariantcaps: 'normal';
                    fontvariantnumeric: 'normal';
                    fontvarianteastasian: 'normal';
                  "
                  fill="currentColor"
                  d="m 38.072951,15.892053 c -4.748151,0
                  -8.521284,2.336684 -8.521284,8.559684 v 0.583944 c 0,5.791201
                  3.10586,8.48372 8.341659,8.48372 1.958775,0 3.330165,-0.300705
                  4.439486,-0.74104 v -3.41943 c -0.814439,0.295849
                  -1.851116,0.553971 -3.336143,0.553971 -3.182544,0
                  -4.285968,-1.777949 -4.285968,-5.02915 v -0.762227 c 0,-3.2766
                  1.56577,-4.622974 4.337663,-4.622974 1.376587,0
                  2.335962,0.197415 3.284448,0.528133 v -3.500561 c
                  -0.95764,-0.414348 -2.266146,-0.63407 -4.259861,-0.63407 z"
                />
              </g>
            </svg>
          </a>
          <a
            href="https://github.com/nathanielcalimag"
            target="_blank"
            rel="noopener noreferrer"
            class="duration-300 sm:hover:text-sky-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="w-7 h-7"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1024 1024"
              aria-labelledby="githubTitle"
            >
              <title id="githubTitle">GitHub link</title>
              <path
                d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5C64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9c26.4 39.1 77.9 32.5 104 26c5.7-23.5 17.9-44.5 34.7-60.8c-140.6-25.2-199.2-111-199.2-213c0-49.5 16.3-95 48.3-131.7c-20.4-60.5 1.9-112.3 4.9-120c58.1-5.2 118.5 41.6 123.2 45.3c33-8.9 70.7-13.6 112.9-13.6c42.4 0 80.2 4.9 113.5 13.9c11.3-8.6 67.3-48.8 121.3-43.9c2.9 7.7 24.7 58.3 5.5 118c32.4 36.8 48.9 82.7 48.9 132.3c0 102.2-59 188.1-200 212.9a127.5 127.5 0 0 1 38.1 91v112.5c.8 9 0 17.9 15 17.9c177.1-59.7 304.6-227 304.6-424.1c0-247.2-200.4-447.3-447.5-447.3z"
                fill="currentColor"
              />
            </svg>
          </a>
          <a
            href="mailto:calimag.nat@gmail.com"
            rel="noopener noreferrer"
            class="duration-300 sm:hover:text-sky-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 256 256"
              aria-labelledby="emailTitle"
            >
              <title id="emailTitle">Email</title>
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM98.7,128,40,181.8V74.2Zm11.8,10.9,12.1,11a8,8,0,0,0,10.8,0l12.1-11L203.4,192H52.6ZM157.3,128,216,74.2V181.8Z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/in/nathanielcalimag/"
            target="_blank"
            rel="noopener noreferrer"
            class="duration-300 sm:hover:text-sky-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 256 256"
              aria-labelledby="linkedinTitle"
            >
              <title id="linkedinTitle">LinkedIn link</title>
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M212,28H44A16.01817,16.01817,0,0,0,28,44V212a16.01833,16.01833,0,0,0,16,16H212a16.01833,16.01833,0,0,0,16-16V44A16.01817,16.01817,0,0,0,212,28ZM96,176.001a8,8,0,1,1-16,0v-64a8,8,0,0,1,16,0ZM88,92a12,12,0,1,1,12-12A12,12,0,0,1,88,92Zm96,84.001a8,8,0,1,1-16,0v-36a20,20,0,0,0-40,0v36a8,8,0,1,1-16,0v-64a7.99729,7.99729,0,0,1,15.79492-1.77857A35.98125,35.98125,0,0,1,184,140.001Z"
              ></path>
            </svg>
          </a>
        </div>
      </div>

      <div class="text-xs">
        &copy; 2023

        <div class="inline-block relative">
          <a
            class="underline bg-clip-text text-transparent bg-gradient-to-r from-red-300 to-purple-400"
            href="https://nathanielcalimag.com"
            >Nathaniel Calimag</a
          >
          <div
            class="absolute w-full h-0.25 bg-gradient-to-r from-red-300 to-purple-400"
          ></div>
        </div>
        - All&nbsp;rights&nbsp;reserved.
      </div>

      <div
        class="absolute -inset-1 bg-gradient-to-r filter rounded-2xl blur-loop from-blue-700 to-purple-800 opacity-40 -z-10"
      ></div>
    </div>
    <Particles
      id="tsparticles"
      class="relative inset-0 -z-20"
      :particlesInit="particlesInit"
      :particlesLoaded="particlesLoaded"
      :options="options"
    />
  </main>
</template>

<script setup>
import { loadFull } from "tsparticles";

const particlesInit = async (engine) => {
  await loadFull(engine);
};

const particlesLoaded = async () => {};

const options = {
  autoPlay: true,
  background: {
    color: {
      value: "#111827",
    },
    position: "50% 50%",
    repeat: "no-repeat",
    size: "20%",
    opacity: 1,
  },
  backgroundMask: {
    composite: "destination-out",
    cover: {
      color: {
        value: "#fff",
      },
      opacity: 1,
    },
    enable: false,
  },
  fullScreen: {
    enable: true,
    zIndex: 1,
  },
  detectRetina: true,
  duration: 0,
  fpsLimit: 120,
  manualParticles: [],
  motion: {
    disable: false,
    reduce: {
      factor: 4,
      value: true,
    },
  },
  particles: {
    bounce: {
      horizontal: {
        random: {
          enable: false,
          minimumValue: 0.1,
        },
        value: 1,
      },
      vertical: {
        random: {
          enable: false,
          minimumValue: 0.1,
        },
        value: 1,
      },
    },
    collisions: {
      bounce: {
        horizontal: {
          random: {
            enable: false,
            minimumValue: 0.1,
          },
          value: 1,
        },
        vertical: {
          random: {
            enable: false,
            minimumValue: 0.1,
          },
          value: 1,
        },
      },
      enable: false,
      mode: "bounce",
      overlap: {
        enable: true,
        retries: 0,
      },
    },
    color: {
      value: "#ffffff",
      animation: {
        h: {
          count: 0,
          enable: false,
          offset: 0,
          speed: 1,
          sync: true,
        },
        s: {
          count: 0,
          enable: false,
          offset: 0,
          speed: 1,
          sync: true,
        },
        l: {
          count: 0,
          enable: false,
          offset: 0,
          speed: 1,
          sync: true,
        },
      },
    },
    destroy: {
      mode: "none",
      split: {
        count: 1,
        factor: {
          random: {
            enable: false,
            minimumValue: 0,
          },
          value: 3,
        },
        rate: {
          random: {
            enable: false,
            minimumValue: 0,
          },
          value: {
            min: 4,
            max: 9,
          },
        },
        sizeOffset: true,
      },
    },
    gradient: [],
    groups: {},
    life: {
      count: 0,
      delay: {
        random: {
          enable: false,
          minimumValue: 0,
        },
        value: 0,
        sync: false,
      },
      duration: {
        random: {
          enable: false,
          minimumValue: 0.0001,
        },
        value: 0,
        sync: false,
      },
    },
    links: {
      blink: false,
      color: {
        value: "#ffffff",
      },
      consent: false,
      distance: 150,
      enable: false,
      frequency: 1,
      opacity: 0.4,
      shadow: {
        blur: 5,
        color: {
          value: "#000",
        },
        enable: false,
      },
      triangles: {
        enable: false,
        frequency: 1,
      },
      width: 1,
      warp: false,
    },
    move: {
      angle: {
        offset: 0,
        value: 90,
      },
      attract: {
        distance: 200,
        enable: false,
        rotate: {
          x: 600,
          y: 600,
        },
      },
      center: {
        x: 50,
        y: 50,
        radius: 0,
      },
      decay: 0,
      distance: {},
      direction: "none",
      drift: 0,
      enable: true,
      gravity: {
        acceleration: 9.81,
        enable: false,
        inverse: false,
        maxSpeed: 50,
      },
      path: {
        clamp: true,
        delay: {
          random: {
            enable: false,
            minimumValue: 0,
          },
          value: 0,
        },
        enable: false,
        options: {},
      },
      outModes: {
        default: "out",
        bottom: "out",
        left: "out",
        right: "out",
        top: "out",
      },
      random: true,
      size: false,
      speed: 1,
      spin: {
        acceleration: 0,
        enable: false,
      },
      straight: false,
      trail: {
        enable: false,
        length: 10,
        fillColor: {
          value: "#000000",
        },
      },
      vibrate: false,
      warp: false,
    },
    number: {
      density: {
        enable: true,
        area: 800,
        factor: 1000,
      },
      limit: 0,
      value: 160,
    },
    opacity: {
      random: {
        enable: true,
        minimumValue: 0.1,
      },
      value: {
        min: 0,
        max: 1,
      },
      animation: {
        count: 0,
        enable: true,
        speed: 1,
        sync: false,
        destroy: "none",
        startValue: "random",
        minimumValue: 0,
      },
    },
    orbit: {
      animation: {
        count: 0,
        enable: false,
        speed: 1,
        sync: false,
      },
      enable: false,
      opacity: 1,
      rotation: {
        random: {
          enable: false,
          minimumValue: 0,
        },
        value: 45,
      },
      width: 1,
    },
    reduceDuplicates: false,
    repulse: {
      random: {
        enable: false,
        minimumValue: 0,
      },
      value: 0,
      enabled: false,
      distance: 1,
      duration: 1,
      factor: 1,
      speed: 1,
    },
    roll: {
      darken: {
        enable: false,
        value: 0,
      },
      enable: false,
      enlighten: {
        enable: false,
        value: 0,
      },
      mode: "vertical",
      speed: 25,
    },
    rotate: {
      random: {
        enable: false,
        minimumValue: 0,
      },
      value: 0,
      animation: {
        enable: false,
        speed: 0,
        sync: false,
      },
      direction: "clockwise",
      path: false,
    },
    shadow: {
      blur: 0,
      color: {
        value: "#000",
      },
      enable: false,
      offset: {
        x: 0,
        y: 0,
      },
    },
    shape: {
      options: {},
      type: "circle",
    },
    size: {
      random: {
        enable: true,
        minimumValue: 1,
      },
      value: {
        min: 1,
        max: 3,
      },
      animation: {
        count: 0,
        enable: false,
        speed: 4,
        sync: false,
        destroy: "none",
        startValue: "random",
        minimumValue: 0.3,
      },
    },
    stroke: {
      width: 0,
    },
    tilt: {
      random: {
        enable: false,
        minimumValue: 0,
      },
      value: 0,
      animation: {
        enable: false,
        speed: 0,
        sync: false,
      },
      direction: "clockwise",
      enable: false,
    },
    twinkle: {
      lines: {
        enable: false,
        frequency: 0.05,
        opacity: 1,
      },
      particles: {
        enable: false,
        frequency: 0.05,
        opacity: 1,
      },
    },
    wobble: {
      distance: 5,
      enable: false,
      speed: 50,
    },
    zIndex: {
      random: {
        enable: false,
        minimumValue: 0,
      },
      value: 0,
      opacityRate: 1,
      sizeRate: 1,
      velocityRate: 1,
    },
  },
  pauseOnBlur: true,
  pauseOnOutsideViewport: true,
  responsive: [],
  style: {},
  themes: [],
  zLayers: 100,
};
</script>

<style scoped>
.blur-loop {
  animation: blur-animation 4s ease-in infinite;
}

@keyframes blur-animation {
  0% {
    filter: blur(12px);
  }
  50% {
    filter: blur(20px);
  }
  100% {
    filter: blur(10px);
  }
}
</style>
